<template>
	<div id="app" class="flex flex-col w-screen h-screen">
		<Loader id="loader" />
		
		<div v-if="customMapInfo" class="w-full px-5 py-2 text-lg text-gray-100 bg-gray-900 border-b-2 border-solid">
			This is a custom map "<span class="font-bold">{{ customMapInfo.name }}</span>" by <span class="italic">{{ customMapInfo.creator }}</span>.
		</div>
		<div v-if="customMapError" class="w-full px-5 py-2 text-lg text-white bg-red-800 border-b-2 border-solid">
			Error loading custom map: {{ customMapError }}
		</div>
		<div id="mapContainer" class="relative w-screen flex-grow">
			<div class="w-full h-full">
				<TrackMap ref="map" :zoom-level="zoomLevel" :inverse-track-sections="inverseTrackSections" :line-colours="lineColours" :track-colours="trackColours" :colours="colours" :track-sections="trackSections"></TrackMap>
			</div>
			<div id="viewControls" class="absolute top-0 right-0 m-5 z-20">
				<div class="bg-white rounded-lg flex flex-col border-2 border-solid border-gray-300 shadow-xl">
					<a @click="zoomIn" @keypress.enter="zoomIn" tabindex="0" class="w-10 h-10 rounded-t-xl flex justify-center items-center text-2xl select-none cursor-pointer hover:bg-gray-50 focus:bg-gray-50 active:bg-gray-50">+</a>
					<div class="w-full border-t-2 border-solid border-gray-300"></div>
					<a @click="zoomOut" @keypress.enter="zoomOut" tabindex="0" class="w-10 h-10 rounded-b-xl flex justify-center items-center text-2xl select-none cursor-pointer hover:bg-gray-50 focus:bg-gray-50 active:bg-gray-50">-</a>
				</div>
			</div>
			<div id="sidebar" class="hidden md:block absolute top-0 left-0 z-20 m-5 w-1/4 xl:w-1/5">
				<div v-if="colourKey != null && colourKey.length > 0" class="bg-white rounded-lg shadow-xl px-3 pt-2 pb-2.5 border-2 border-solid border-gray-300 overflow-y-auto" style="max-height: 75vh;">
					<a @click="expanded = expanded == 'key' ? false : 'key'" @keypress.enter="expanded = expanded == 'key' ? false : 'key'" tabindex="0" aria-label="Toggle map key" class="w-full flex cursor-pointer">
						<div class="flex-grow text-lg font-bold text-gray-800">Key</div>
						<div class="px-1 pt-1 cursor-pointer">
							<svg class="h-2 w-auto mt-2 text-gray-800" :class="{ 'transform rotate-180': expanded == 'key' }" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</div>
					</a>
					
					<div v-if="expanded == 'key'">
						<div v-for="(colour, key) in colourKey" :key="'colourKey-' + key" class="flex w-full mt-1">
							<div class="flex-shrink-0 w-3 h-3 mr-2 rounded-full mt-1.5" :style="{ backgroundColor: colour.hex }"></div>
							<div class="flex-grow">
								<div class="text-base text-gray-800">{{ colour.label }}</div>
								<div v-if="colour.description" class="text-sm text-gray-600 leading-tight -mt-0.5">{{ colour.description }}</div>
							</div>
						</div>
					</div>
				</div>
				
				<div v-if="hasExtraOptions" class="bg-white rounded-lg shadow-xl px-3 pt-2 pb-2.5 border-2 border-solid border-gray-300" :class="{ 'mt-4': colourKey != null && colourKey.length > 0 }">
					<a @click="expanded = expanded == 'extraOptions' ? false : 'extraOptions'" @keypress.enter="expanded = expanded == 'extraOptions' ? false : 'extraOptions'" tabindex="0" aria-label="Toggle extra options" class="w-full flex cursor-pointer">
						<div class="flex-grow text-lg font-bold text-gray-800">Options</div>
						<div class="px-1 pt-1 cursor-pointer">
							<svg class="h-2 w-auto mt-2 text-gray-800" :class="{ 'transform rotate-180': expanded == 'extraOptions' }" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</div>
					</a>
					
					<div v-if="expanded == 'extraOptions'">
						<div v-if="extraOptions.enableInverseToggle" class="flex w-full">
							<div class="block flex-grow text-base py-0.5">Inverse colours</div>
							<div class="flex-shrink">
								<ToggleSwitch class="my-1.5" :value="inverseTrackSections" :onUpdate="(value) => { inverseTrackSections = value }" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="bottomInfo" class="hidden md:block absolute bottom-0 right-0 z-20">
				<div class="bg-white rounded-tl-lg shadow-xl flex items-center px-3 py-1.5 border-t-2 border-l-2 border-solid border-gray-300">
					<span class="text-lg text-gray-500 mr-2">&copy;</span>
					<a href="https://tractionclogs.co.uk/" target="_blank" rel="noopener" tabindex="0" title="Traction Clogs" aria-label="Traction Clogs" class="text-black hover:text-red-800 focus:text-red-800 active:text-red-800">
						<TractionClogsLogo class="w-auto h-5" />
					</a>
					<span class="text-lg text-gray-500 ml-2">2021 - 2023</span>
				</div>
			</div>
			<div id="bottombar" class="flex md:hidden flex-col absolute bottom-0 left-0 z-20 w-full h-auto max-h-3/4 bg-white shadow-xl border-t border-solid border-gray-300">
				<a v-if="colourKey != null && colourKey.length > 0" @click="expanded = expanded == 'key' ? false : 'key'" @keypress.enter="expanded = expanded == 'key' ? false : 'key'" tabindex="0" aria-label="Toggle map key" class="w-full flex px-3 pt-2 pb-2.5 border-t border-b border-solid border-gray-300 cursor-pointer">
					<div class="flex-grow text-lg font-bold text-gray-800">Key</div>
					<div class="px-2 pt-1">
						<svg class="h-2 w-auto mt-2 text-gray-400" :class="{ 'transform rotate-180': expanded == 'key' }" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</div>
				</a>
				
				<div v-if="colourKey != null && colourKey.length > 0 && expanded == 'key'" class="flex-grow overflow-y-auto px-3 pt-1 pb-2.5 border-b border-solid border-gray-300 overflow-y-auto" style="max-height: 50vh;">
					<div v-for="(colour, key) in colourKey" :key="'colourKey-' + key" class="flex w-full mt-1">
						<div class="flex-shrink-0 w-3 h-3 mr-2 rounded-full mt-1.5" :style="{ backgroundColor: colour.hex }"></div>
						<div class="flex-grow">
							<div class="text-base text-gray-800">{{ colour.label }}</div>
							<div v-if="colour.description" class="text-sm text-gray-600 leading-tight -mt-0.5">{{ colour.description }}</div>
						</div>
					</div>
				</div>
				
				<a v-if="hasExtraOptions" @click="expanded = expanded == 'extraOptions' ? false : 'extraOptions'" @keypress.enter="expanded = expanded == 'extraOptions' ? false : 'extraOptions'" tabindex="0" aria-label="Toggle map key" class="w-full flex px-3 pt-2 pb-2.5 border-t border-b border-solid border-gray-300 cursor-pointer">
					<div class="flex-grow text-lg font-bold text-gray-800">Options</div>
					<div class="px-2 pt-1">
						<svg class="h-2 w-auto mt-2 text-gray-400" :class="{ 'transform rotate-180': expanded == 'extraOptions' }" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</div>
				</a>
				
				<div v-if="hasExtraOptions && expanded == 'extraOptions'" class="flex-grow overflow-y-auto px-3 pt-2 pb-2.5 border-b border-solid border-gray-300">
					<div v-if="extraOptions.enableInverseToggle" class="flex w-full">
						<div class="block flex-grow text-base py-0.5">Inverse colours</div>
						<div class="flex-shrink">
							<ToggleSwitch class="my-1.5" :value="userInverseTrackSections" :onUpdate="(value) => { userInverseTrackSections = value; inverseTrackSections = !inverseTrackSections }" />
						</div>
					</div>
				</div>
				
				<a @click="expanded = expanded == 'info' ? false : 'info'" @keypress.enter="expanded = expanded == 'info' ? false : 'info'" tabindex="0" aria-label="Toggle map info" class="w-full flex px-3 pt-2 pb-2.5 border-t border-b border-solid border-gray-300 cursor-pointer">
					<div class="flex-grow text-lg font-bold text-gray-800">Info</div>
					<div class="px-2 pt-1">
						<svg class="h-2 w-auto mt-2 text-gray-400" :class="{ 'transform rotate-180': expanded == 'info' }" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</div>
				</a>
				
				<div v-if="expanded == 'info'" class="flex-grow overflow-y-auto px-3 pt-2 pb-2.5 border-b border-solid border-gray-300">
					<p class="text-lg text-gray-600 mb-2">Tube track map by Arturs Dobrecovs. Correct as of January 2023.</p>
					
					<div class="flex items-center">
						<span class="text-lg text-gray-500 mr-2">&copy;</span>
						<a href="https://tractionclogs.co.uk/" target="_blank" rel="noopener" tabindex="0" title="Traction Clogs" aria-label="Traction Clogs" class="text-black hover:text-red-800 focus:text-red-800 active:text-red-800">
							<TractionClogsLogo class="w-auto h-5" />
						</a>
						<span class="text-lg text-gray-500 ml-2">2021-2023</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import svgPanZoom from "svg-pan-zoom";
import Loader from "./components/Loader.vue";
import ToggleSwitch from "./components/ToggleSwitch.vue";
import TractionClogsLogo from "./components/TractionClogsLogo.vue";

export default {
	name: "App",
	components: {
		Loader,
		ToggleSwitch,
		TractionClogsLogo
	},
	data() {
		return {
			loading: true,
			svgPanZoom: null,
			expanded: false,
			zoomLevel: 1,
			customMapInfo: null,
			customMapError: null,
			inverseTrackSections: false,
			userInverseTrackSections: false,
			colourKey: [],
			lineColours: {
				background: "#DBDBDB",
				bakerloo: "#894E24",
				central: "#EE2E22",
				circle: "#FED105",
				district: "#00843D",
				hammersmithCity: "#E48BA1",
				jubilee: "#9D9D9D",
				metropolitan: "#751056",
				northern: "#000000",
				piccadilly: "#1B3F94",
				victoria: "#00A0E2",
				waterlooCity: "#95CCBD"
			},
			trackColours: {},
			colours: [],
			trackSections: {
				bakerloo: [],
				central: [],
				circle: [],
				district: [],
				jubilee: [],
				metropolitan: [],
				northern: [],
				piccadilly: [],
				victoria: [],
				waterlooCity: []
			},
			extraOptions: {
				enableInverseToggle: false
			}
		}
	},
	watch: {
		colours(newColours) {
			this.processColours(newColours);
		}
	},
	mounted() {
		window.addEventListener("resize", this.onResize, false);
		window.addEventListener("message", this.onMessage, false);
		this.$root.$on('mapzoom', this.onMapZoom);
		this.$root.$on('maploaded', this.onMapLoaded);
		
		if (window.location.pathname.substring(0, 1) == "/" && window.location.pathname.length > 3) {
			var map_key = window.location.pathname.substring(1);
			console.log(map_key);
			
			window.fetch(`https://map-api.tractionclogs.co.uk/getMap/${map_key}`)
			.then(async (response) => {
				var data = await response.json();
				
				if (!response.ok) throw data.error;
				
				if (data.mapInfo) this.customMapInfo = data.mapInfo;
				if (data.inverseTrackSections) this.inverseTrackSections = data.inverseTrackSections;
				if (data.colourKey) this.colourKey = data.colourKey;
				if (data.trackColours) this.trackColours = data.trackColours;
				if (data.selection) this.trackSections = data.selection;
				if (data.extraOptions) this.extraOptions = data.extraOptions;
			})
			.catch(err => {
				var error = err;
				
				switch (err) {
					case "MAP_NOT_FOUND":
						error = "a map with this ID does not exist."
						break;
				}
				
				this.customMapError = error;
			});
		}
	},
	computed: {
		hasExtraOptions() {
			var hasOptions = false;
			
			var keys = Object.keys(this.extraOptions);
			for (var i = 0; i < keys.length; i++) {
				if (this.extraOptions[keys[i]]) hasOptions = true;
			}
			
			return hasOptions;
		}
	},
	methods: {
		registerSvgPanZoom(svgpanzoom) {
			this.svgPanZoom = svgpanzoom;
		},
		onMapZoom(zoom) {
			this.zoomLevel = zoom;
		},
		onMapLoaded() {
			document.getElementById('loader').style.display = "none";
			if (window.parent) window.parent.postMessage({ loaded: true }, "*");
			
			var beforePan = function(oldPan, newPan) {
				var stopHorizontal = false,
				stopVertical = false,
				gutterWidth = 3 * window.innerWidth / 4,
				gutterHeight = 3 * window.innerHeight / 4,
				// Computed variables
				sizes = this.getSizes(),
				leftLimit = -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + gutterWidth,
				rightLimit = sizes.width - gutterWidth - (sizes.viewBox.x * sizes.realZoom),
				topLimit = -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + gutterHeight,
				bottomLimit = sizes.height - gutterHeight - (sizes.viewBox.y * sizes.realZoom);
				
				var customPan = {};
				customPan.x = Math.max(leftLimit, Math.min(rightLimit, newPan.x));
				customPan.y = Math.max(topLimit, Math.min(bottomLimit, newPan.y));
				
				return customPan;
			}
			
			var onZoom = (newZoom) => {
				this.$root.$emit('mapzoom', newZoom);
			}
			
			this.svgPanZoom = svgPanZoom("#mapContainer svg", {
				viewportSelector: "#Map",
				panEnabled: true,
				controlIconsEnabled: false,
				zoomEnabled: true,
				dblClickZoomEnabled: true,
				mouseWheelZoomEnabled: true,
				zoomScaleSensitivity: 0.2,
				minZoom: 0.6,
				maxZoom: 18,
				fit: true,
				contain: true,
				center: true,
				refreshRate: "auto",
				beforePan: beforePan,
				onZoom: onZoom,
			});
		},
		onResize() {
			this.svgPanZoom.resize();
			this.svgPanZoom.fit();
			this.svgPanZoom.center();
		},
		processColours(colours) {
			var count = colours.length;
			for (var i = 0; i < count; i++) {
				var colour = colours[i];
				
				if (colour.type == "reset") {
					this.trackColours = {};
					continue;
				}
				
				if (colour.type == "background" && this.isValidHex(colour.hex)) {
					this.lineColours.background = colour.hex;
				}
				
				if (colour.type == "line" && this.lineColours[colour.line] && this.isValidHex(colour.hex)) {
					this.lineColours[colour.line] = colour.hex;
				}
				
				if (colour.type == "section" && this.isValidHex(colour.hex)) {
					for (var j = 0; j < colour.sections.length; j++) {
						this.trackColours[colour.sections[j]] = colour.hex;
					}
				}
			}
		},
		onMessage(event) {
			var origin = event.origin;
			var data = event.data;
			
			var lines = ["background", "bakerloo", "central", "circle", "district", "hammersmithCity", "jubilee", "metropolitan", "northern", "piccadilly", "victoria", "waterlooCity"];
			
			if (typeof data.lineColours != "undefined") {
				for (var i = 0; i < lines.length; i++) {
					var line = lines[i];
					if (data.lineColours[line] && this.isValidHex(data.lineColours[line])) this.lineColours[line] = data.lineColours[line];
				}
			}
			
			if (typeof data.trackColours != "undefined") {
				this.processColours(data.trackColours);
			}
			
			if (typeof data.trackSections != "undefined") {
				for (var i = 0; i < lines.length; i++) {
					var line = lines[i];
					if (data.trackSections[line] && Array.isArray(data.trackSections[line])) this.trackSections[line] = data.trackSections[line];
				}
			}
			
			if (typeof data.colourKey != "undefined") {
				// TODO: validate data
				this.colourKey = data.colourKey;
			}
			
			if (typeof data.inverseTrackSections != "undefined" && (data.inverseTrackSections === true || data.inverseTrackSections === false)) {
				this.inverseTrackSections = data.inverseTrackSections;
			}
			
			if (typeof data.extraOptions != "undefined") {
				if (typeof data.extraOptions.enableInverseToggle == "boolean") {
					this.extraOptions.enableInverseToggle = data.extraOptions.enableInverseToggle;
				}
			}
		},
		isValidHex(hex) {
			return typeof hex === 'string'
				&& hex.length === 7
				&& !isNaN(Number('0x' + hex.substring(1)));
		},
		zoomIn() {
			this.svgPanZoom.zoomBy(1.3);
		},
		zoomOut() {
			this.svgPanZoom.zoomOut(0.7);
		}
	}
};
</script>