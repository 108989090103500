import Vue from 'vue';
import App from './App.vue';
import LZUTF8 from 'lzutf8';

Vue.config.productionTip = false;

Vue.component('TrackMap', function(resolve, reject) {
	fetch('map.txt')
	.then(response => response.text())
	.then((data) => {
		var template = LZUTF8.decompress(data, {
			inputEncoding: "Base64",
			outputEncoding: "String"
		});
		
		resolve({
			template: template,
			props: ['zoomLevel', 'inverseTrackSections', 'lineColours', 'trackColours', 'colours', 'trackSections'],
			methods: {
				showTrack(line, id) {
					try {
						var parts = id.split("-");
						var id1 = parts[0];
						var id2 = parts[0] + "-" + parts[1];
						var id3 = id;
						return (!this.inverseTrackSections && (this.trackSections[line].includes(id1) || this.trackSections[line].includes(id2) || this.trackSections[line].includes(id3)))
							|| (this.inverseTrackSections && !(this.trackSections[line].includes(id1) || this.trackSections[line].includes(id2) || this.trackSections[line].includes(id3)));
					} catch (err) {
						console.error(err);
						console.log(line);
						console.log(id);
					}
				},
				trackFill(line, id) {
					var trackSectionLine = line == "hammersmithCity" ? "circle" : line;
					if (!this.showTrack(trackSectionLine, id)) {
						return this.lineColours['background'];
					}
					
					return this.trackColours[id] ? this.trackColours[id] : this.lineColours[line];
				},
				onClick(line, id) {
					if (window.parent) {
						window.parent.postMessage({
							trackSectionClicked: {
								line: line,
								id: id
							}
						}, "*");
					}
				}
			},
			mounted() {
				this.$root.$emit('maploaded');
			}
		});
	})
	.catch((error) => {
		reject(error);
	});
});

new Vue({
	render: function (h) { return h(App) },
}).$mount('#app');