<template>
	<label class="block relative cursor-pointer">
		<div class="w-14 h-4 bg-white border-2 border-solid border-gray-400 rounded-lg" :class="{ 'bg-red-800 border-red-800': value == true, 'border-gray-400': value == false }"></div>
		<div v-if="value != true" class="absolute top-0 right-0 text-xs mx-1.5 text-gray-500">OFF</div>
		<div v-if="value == true" class="absolute top-0 left-0 text-xs mx-2 text-white">ON</div>
		<div class="absolute top-0 left-0 w-6 h-6 -my-1 transition-all rounded-full" :class="{ 'ml-8 bg-black': value == true, 'bg-gray-500': value == false }"></div>
		<input v-model="tempValue" :value="value" @change="onUpdate(tempValue)" type="checkbox" class="sr-only" />
	</label>
</template>

<script>
export default {
	props: ["value", "onUpdate"],
	data() {
		return {
			tempValue: false
		}
	},
	mounted() {
		this.tempValue = this.value;
	}
}
</script>